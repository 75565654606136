import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Crystal Clean Carwash
			</title>
			<meta name={"description"} content={"Блиск, на який заслуговує ваше авто"} />
			<meta property={"og:title"} content={"Crystal Clean Carwash"} />
			<meta property={"og:description"} content={"Блиск, на який заслуговує ваше авто"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/pngtree-car-wash-icon-blue-liquid-logo-vector-png-image_615059.png?v=2024-06-25T08:43:35.281Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/pngtree-car-wash-icon-blue-liquid-logo-vector-png-image_615059.png?v=2024-06-25T08:43:35.281Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/pngtree-car-wash-icon-blue-liquid-logo-vector-png-image_615059.png?v=2024-06-25T08:43:35.281Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/pngtree-car-wash-icon-blue-liquid-logo-vector-png-image_615059.png?v=2024-06-25T08:43:35.281Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/pngtree-car-wash-icon-blue-liquid-logo-vector-png-image_615059.png?v=2024-06-25T08:43:35.281Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/pngtree-car-wash-icon-blue-liquid-logo-vector-png-image_615059.png?v=2024-06-25T08:43:35.281Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/pngtree-car-wash-icon-blue-liquid-logo-vector-png-image_615059.png?v=2024-06-25T08:43:35.281Z"} />
		</Helmet>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"666ac2cae8900d0022f35234"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});